import config from '../../../config.json';
import './SearchAddress.scss';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import TokenOutlined from '@mui/icons-material/TokenOutlined';
import style from '../../../shared.scss'
import { useState } from 'react';
import BalanceTable from '../balancetable/BalanceTable';
import Snackbar from '@mui/joy/Snackbar';

function SearchAddress() {
    const [showBalance, setShowBalance] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [address, setAddress] = useState("");
    const [balanceData, setBalanceData] = useState();
    const [showSnack, setShowSnack] = useState(false);
    const [snackText, setSnackText] = useState("");

    const onSearch = () => {
        if(address.length < 1){
            setSnackText("Address can't be ampty!")
            setShowSnack(true);
            return;
        }

        setShowLoading(true);
        setShowBalance(true);
        // Load data
        fetch(config.api_server_url + "/address/" + address + "/balance")
            .then((res) => {
                if (res.ok)
                    return res.json()
                throw Error(res.status);
            })
            .then((json) => {
                setBalanceData(json);
                setShowLoading(false);
            })
            .catch(error => {
                if (error.message === "429")
                    setSnackText("Error! To many requests. Please try again later")
                else
                    setSnackText("Server error. Please try again later or contact us using mail from footer");

                setShowSnack(true);
                setShowLoading(false);
                setShowBalance(false);
            });
    }

    const onAddressChange = (event) => {
        setAddress(event.target.value);
    };
    return (
        <div id="search-container-section" className={(showBalance ? "with-balance" : "")}>
            <div className={"search-container"}>
                <div className={showLoading ? "hidden" : ""}>
                    <div className='search-info'>Provide address to check your tokens</div>
                    <div className="bsearch-address-box">
                        <Input
                            autoFocus
                            variant="outlined"
                            placeholder="Search address"
                            required
                            onKeyDown={event => {
                                if (event.key === 'Enter') {
                                    onSearch()
                                }
                            }}
                            onChange={onAddressChange}
                            value={address}
                            sx={{
                                "--Input-radius": "8px 0 0 8px",
                                '&::before': {
                                    transition: 'box-shadow .15s ease-in-out',
                                },
                                '&:focus-within': {
                                    borderColor: style.primary900,
                                }
                            }}
                            startDecorator={<TokenOutlined sx={{ fontSize: 21, color: style.pageTextColor }} />}
                        />
                        <Button className="search-address-button"
                            onClick={onSearch}
                            sx={{
                                "--Button-radius": "0 8px 8px 0",
                            }}>Search</Button>
                    </div>
                </div>

                <BalanceTable address={address} balanceData={balanceData} showBalance={showBalance} showLoading={showLoading}></BalanceTable>
            </div>

            <Snackbar autoHideDuration={3000} open={showSnack}
                onClose={() => {
                    setShowSnack(false);
                }}
                sx={(theme) => ({
                    marginTop: "80px"
                })}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                color="danger">
                {snackText}
            </Snackbar>
        </div>
    );
}

export default SearchAddress;
