import React from 'react';
import ReactDOM from 'react-dom/client';
import '@fontsource/inter';
import './index.scss';
import { CssVarsProvider, extendTheme } from '@mui/joy/styles';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "./Theme";
import Layout from './pages/Layout';
import style from './shared.scss'
import Home from './pages/home/Home';
import NoPage from './pages/nopage/NoPage';

const theme = extendTheme({ 
  cssVarPrefix: 'page', 
  colorSchemes: {
    dark: {
      palette: {
        primary: {
          500: style.primatry500,
          700: style.primary700,
          solidBg: style.solidBg,
          solidHoverBg: style.solidHoverBg,
          solidActiveBg: style.solidActiveBg
        }
      }
  },

  }});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <CssVarsProvider
    defaultMode="dark"
    theme={theme}
    // the selector to apply CSS theme variables stylesheet.
    colorSchemeSelector="#dark-mode"
    // the local storage key to use
    modeStorageKey="dark-mode"
    // set as root provider
    disableNestedContext>

    <ThemeProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>

  </CssVarsProvider>
);