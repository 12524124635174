import './NoPage.scss';

function NoPage() {
  return (
      <div className='no-page-container'>
        404 No Page
      </div>
  );
}

export default NoPage;
