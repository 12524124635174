import { useContext } from "react";
import { Outlet } from "react-router-dom";
import './Layout.scss';
import TopBar from '../components/Topbar/TopBar';
import { ThemeContext } from "../Theme";
import Footer from "../components/Footer/Footer";

function Layout() {
    const { theme } = useContext(ThemeContext);


    return (
        <div className={`App ${theme}`}>
            <TopBar></TopBar>
            <div className="content-wrapper">
                <Outlet />
            </div>
            <Footer></Footer>
        </div>
    );
}

export default Layout;
