import './BalanceTable.scss';
import PropagateLoader from "react-spinners/PropagateLoader";
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import Table from '@mui/joy/Table';
import style from '../../../shared.scss'
import icons from "./icons.json"

function BalanceTable({ addressToSearch, balanceData, showBalance, showLoading }) {

    const TableElement = () => {
        if (!balanceData)
            return null;

        return (
            <div className={"address-balance " + (showBalance && !showLoading ? "" : "hidden")}>
                <div className="table-desc">Balance for address: <span className="bold">{addressToSearch}</span></div>
                <Table variant="plain">
                    <thead>
                        <tr>
                            <th>Symbol</th>
                            <th className="th-amount">Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(balanceData).map(([key, value]) => (
                            <tr key={key}>
                                <td className="td-token">
                                    <img src={icons[key]} />
                                    {key}
                                </td>
                                <td className="td-amount">{value}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        );
    }

    return (
        <div>
            <div className={"balance-loading " + (showLoading ? "" : "hidden")}>
                <div>Please wait.</div>
                <div>Searching transactions for address {addressToSearch}</div>
                <PropagateLoader
                    color={style.primary}
                    loading={true}
                    size={15}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </div>
            <TableElement></TableElement>
        </div>
    );
}

export default BalanceTable;