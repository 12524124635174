import './Footer.scss';
import CopyrightIcon from '@mui/icons-material/Copyright';

function Footer() {

    return (
        <div className='page-footer'>
            <div className='footer-element'>
                <span className="footer-copyright">
                    <div>Bit<span>Cusio</span></div>
                    <div><CopyrightIcon />2024</div>
                </span>
            </div>
            <div className='footer-element'>
                <span>Read Privacy</span>
            </div>
            <div className='footer-element'>
                <span>contact@bitcus.io</span>
            </div>
        </div>
    );
}

export default Footer;