import { useContext } from "react";
import './TopBar.scss';
import { Link, animateScroll as scroll } from 'react-scroll'
import { ThemeContext } from "../../Theme";

function TopBar() {
  const { theme, toggleTheme } = useContext(ThemeContext);

  return (
    <div className="top-bar-container" id="top-bar">
      <div className="top-bar">
        <div className="logo-container">
          <a href="/">
            <img src="/images/logo.png" className="logo" alt="MoonStock" />
            <div className="logo-text">Bit<span>Cusio</span></div>
          </a>
        </div>
        <div className="links">
          {/* <NavLink to="/">HOME</NavLink> */}
          <a onClick={() => {
            scroll.scrollToTop({ duration: 500 });
          }}>INDEXER
          </a>
        </div>
      </div>
    </div>
  );
}

export default TopBar;