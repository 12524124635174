import './Home.scss';
import React from 'react';
import SearchAddress from './searchaddress/SearchAddress';
import { useState } from 'react';
import Snackbar from '@mui/joy/Snackbar';

function Home() {

  const [showSnack, setShowSnack] = useState(false);
  const [snackText, setSnackText] = useState("");

  return (
    <div>
      <SearchAddress></SearchAddress>
      <Snackbar autoHideDuration={3000} open={showSnack}
        onClose={() => {
          setShowSnack(false);
        }}
        sx={(theme) => ({
          marginTop: "80px"
        })}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        color="danger">
        {snackText}
      </Snackbar>
    </div >
  );
}

export default Home;
